/*
@contract drawer-menu {
  trigger {
    color: Color
    font-size: FontSize
    padding: Padding
  }
  link {
    weight: FontWeight
    color: Color
    hover-color: Color
    background-color: BackgroundColor
    background-hover-color: BackgroundColor
  }
  divider-margin-top: MarginTop
  divider-border: Border
  non-link-background-color: BackgroundColor
  non-link-color: Color
}
*/

.dialog {
  --modal-max-height: 100%;
  --modal-height: 100%;
  --modal-background-color: var(--color-white);
  --modal-margin: 0;
  --modal-panel-overflow: hidden;
  padding: 0;
  width: 300px;

  @media (--screen-md) {
    max-width: 300px;
  }
}

.hamburgerIcon {
  width: 24px;
}

.hamburgerButton {
  --icon-button-display: inline-block;
  height: var(--page-header-height);
  font-size: var(--drawer-menu-trigger-font-size);
  color: var(--drawer-menu-trigger-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--drawer-menu-trigger-padding);
}

.closeButton {
  --button-padding: var(--space-3x) var(--space-1x);
}

.count {
  width: 20px;
  height: 20px;
  background: var(--color-green-600);
  color: var(--color-white);
  font-weight: bold;
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-full);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header {
  --icon-button-icon-display: flex;
  --button-padding: 0;
  --anchor-gap: var(--space-2x);
  --anchor-placement: center;
  --modal-close-button-position: static;
  border-bottom: 1px solid var(--color-gray-200);
  margin: 0 var(--space-3x);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--space-3x) 0;
  color: var(--drawer-menu-link-color);
}

.drawerMenuCloseBtn {
  font-weight: bold;
  color: var(--drawer-menu-link-color);
  padding: var(--space-none);
}

.body {
  overflow-y: auto;
  height: calc(100vh - var(--space-16x));
  padding-bottom: var(--space-24x);

  @media (--screen-lg) {
    padding-bottom: 0;
  }
}

.list {
  list-style: none;
  padding: var(--space-3x);
}

.item {
  font-weight: var(--drawer-menu-link-weight);
  border-radius: var(--border-radius-2x);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex;
  color: var(--drawer-menu-link-color);
  text-decoration: none;
  border: none;
  width: 100%;
  background: var(--drawer-menu-link-background-color);
  padding: var(--space-3x) var(--space-2x);
}

.item:hover,
.item:focus {
  background: var(--drawer-menu-link-background-hover-color);
  color: var(--drawer-menu-link-hover-color);
}

.itemWithDescription {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--space-3-5x) var(--space-2-5x);
}

.description {
  color: var(--color-gray-500);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
}

.divider {
  border-top: var(--drawer-menu-divider-border);
  margin-top: var(--drawer-menu-divider-margin-top);
  padding-top: var(--space-3x);
}

.divider:first-child {
  border: 0;
  margin-top: 0;
  padding-top: 0;
}

.profileName {
  color: var(--color-gray-500);
  font-weight: var(--font-weight-semibold);
}

.nextIcon {
  color: var(--color-gray-500);
  font-size: var(--font-size-sm);
}

.capitalize {
  text-transform: capitalize;
}

.leftAlign {
  justify-content: flex-start;
}

.menu {
  font-size: var(--font-size-md);
  height: 100%;
  width: 300px;
  position: relative;
}

.submenu {
  height: 100%;
  width: 300px;
  min-width: 300px;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  padding: 0;
  z-index: var(--z-index-4x);
  visibility: hidden;
  transform: translateX(300px);
  transition: transform 300ms ease-in-out,
    display 300ms ease-in-out allow-discrete,
    overlay 300ms ease-in-out allow-discrete,
    visibility 300ms ease-in-out allow-discrete;
}

.submenu[open] {
  transform: translateX(0);
  visibility: visible;
}

@starting-style {
  .submenu[open] {
    transform: translateX(300px);
  }
}
